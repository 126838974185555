<template>
  <ModalLayout
    v-model="isOpen"
    content-class="UserGroupSelectDialog"
    :title="$t('userGroup.SelectUserGroup')"
    min-footer-height="64px"
    :max-width="500"
    :padding="0"
    max-body-height="408px"
  >
    <div
      class="py-6 flex-grow-1"
      style="overflow: hidden auto; min-height: min(408px, 80vh)"
    >
      <UserGroupSelector
        v-model="selectedGroupIds"
        multiple
      />
    </div>

    <template #footer="{ close }">
      <v-spacer />
      <v-btn
        color="primary"
        depressed
        :disabled="!selectedGroupIds.length"
        :loading="submitting"
        @click="submit"
      >
        {{ $t('userGroup.AddToGroup') }}
      </v-btn>
      <v-btn
        color="primary"
        outlined
        class="ml-4"
        @click="close"
      >
        {{ $t('layout.Cancel') }}
      </v-btn>
    </template>
  </ModalLayout>
</template>

<script>
import { ModalLayout } from '@hexway/shared-front'

import UserGroup from '@/store/orm/userGroup'

import UserGroupSelector from '@/components/UserGroupSelector'

export default {
  name: 'UserGroupSelectDialog',

  components: {
    ModalLayout,
    UserGroupSelector,
  },

  props: {
    dialogInstance: { type: Object, required: true },
    userIds: { type: Array, required: true },
  },

  data() {
    return {
      selectedGroupIds: [],
      submitting: false,
    }
  },

  computed: {
    isOpen: {
      get() { return this.dialogInstance.isOpen },
      set(isOpen) { this.dialogInstance.onDialogModelInput(isOpen) },
    },
  },

  methods: {
    async submit() {
      this.submitting = true
      try {
        await Promise.all(this.selectedGroupIds.map(userGroupId =>
          UserGroup.dispatch('$addUsers', {
            userGroupId,
            userIds: this.userIds,
          })))
      } catch (e) {
        console.error(e)
        return
      } finally {
        this.submitting = false
      }
      this.isOpen = false
      this.$emit('success')
    },
  },
}
</script>
