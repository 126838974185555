<template>
  <TypeToSelect
    v-model="selectionModel"
    :filter="filter"
    :items="groups"
    extra-item-padding
    :search-placeholder="$t('userGroup.SearchGroup')"
    :multiple="multiple"
  >
    <template #item="{ item: group }">
      <div class="flex-grow-1 flex-shrink-0 align-self-start text-truncate">
        <div
          class="font-weight-medium mb-1"
          v-text="group.name"
        />
        <div
          class="textSecondary--text text-truncate"
          v-text="group.description"
        />
      </div>
    </template>
  </TypeToSelect>
</template>

<script>
import * as R from 'ramda'

import UserGroup from '@/store/orm/userGroup'

import TypeToSelect from '@/components/TypeToSelect'

export default {
  name: 'UserGroupMemberSelector',

  components: {
    TypeToSelect,
  },

  props: {
    value: { type: String, default: null }, // Group UUID(s)
    multiple: { type: Boolean, default: false },
  },

  data() {
    return {
      selectionModel: R.clone(this.value),
    }
  },

  computed: {
    groups() { return UserGroup.all() },
  },

  watch: {
    value: {
      handler(value) {
        if (R.equals(value, this.selectionModel)) return
        this.selectionModel = R.clone(value)
      },
    },
    selectionModel: {
      handler(selectionModel) {
        if (R.equals(selectionModel, this.value)) return
        this.$emit('input', R.clone(selectionModel))
      },
    },
  },

  methods: {
    filter(group, q) {
      return group._searchBy.includes(q)
    },
  },
}
</script>
